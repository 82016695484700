
  import {
    required,
    requiredIf,
    email,
    minLength,
    sameAs,
  } from 'vuelidate/lib/validators';
  
  import {
    Vue
  } from 'vue-property-decorator';
  
  
  import headerView from './page/Header.vue';
  import footerView from './page/Footer.vue';
  import axios from 'axios'
  import starRating from "vue-star-rate";
  


  declare let apigClientFactory: any;
  
  export default Vue.extend({
    name: 'home',
    components: {
      headerView,
      footerView,
      starRating
    },
    data: () => {
      return {
        submitted: false,
        isDisabled: false,
        isLoading: false,
        isCheacked: false,
        score: 0,
        color:'#FFD600',
        size:3,
        maxScore:5,
        readOnly:false,
        errorMessage: "",
        message: "", 
        reason:'',
        options: [] as any[],
        maxRating: 10,
        rating: 10,
      }
  
    },
  
    // validations: {
    //     reason: {
    //       required: requiredIf((v: any) => `${v.reason}` === ''),
    //       minLength: minLength(15),
    //     }
    // },
  
    methods: {
      generateOptions(){
          for(let i=this.maxRating; i>0; i--){
              this.options.push({
                  item: i,
                  name: i
              })
          }
      },
      showModal() {
        this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow')
      },
  
      hideModal() {
        this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
  
      },
  
      showModal2() {
        this.$root.$emit('bv::show::modal', 'modal-2', '#btnShow')
      },
  
      hideModal2() {
        this.$root.$emit('bv::hide::modal', 'modal-2', '#btnShow')
      },

      showModal4() {
        this.$root.$emit('bv::show::modal', 'modal-4', '#btnShow')
      },
  
      hideModal4() {
        this.$root.$emit('bv::hide::modal', 'modal-4', '#btnShow')
      },

    
  
      handleSubmit(e: any) {
        this.submitted = true;
        // stop here if form is invalid
        // this.$v.$touch();
        if (this.rating < 10 && this.reason == '') {
          this.showModal()
          return;
        } else {
          this.isLoading = true;
          this.isDisabled = true;
          this.submitForm();
          

        }
      },

      

      async submitForm() {
  
         this.message = "sedang memproses data"
        const auth = this.$store.getters.getRoomID() !== '' ? this.$store.getters.getRoomID() : null
        // console.log('auth', auth)
        const params = {
          // This is where any header, path, or querystring request params go.
          authorization: auth,
        };
        const body = {
          // This is where you define the body of the request
          score: this.rating,
          notes: this.reason
        };

        const additionalParams = {};
        const apigClient = apigClientFactory.newClient();
        // console.log('body', body);
  
        apigClient.apiV1QuestionerCreatePost(params, body, additionalParams)
          .then((response: any) => {
            this.$router.push("/success");
          }).catch((error: any) => {
            this.submitted = false;
            this.isLoading = false;
            this.errorMessage = 'Terjadi kesalahan ';
            this.showModal4();
            // console.log('error');
  
          })
         
      },

      featchValidity() {
        this.isLoading = true;
        const params = {
          // This is where any header, path, or querystring request params go.
        };
        const body = {
          // This is where you define the body of the request
          roomId: this.$store.getters.getCreatedId()
  
        };
        const additionalParams = {};
        const apigClient = apigClientFactory.newClient();
        apigClient.apiV1IdentityValidatePost(params, body, additionalParams)
          .then((response: any) => {
            this.isLoading = false;
            // console.log("response", response)
  
          }).catch((error: any) => {
            // console.log("error", error)
             this.isLoading = false;
            if (error.data.statusCode === 400) {
              this.$router.push(`/${this.$store.getters.getCreatedId()}`);
            } else if (error.data.statusCode === 401) {
               this.$router.push(`/${this.$store.getters.getCreatedId()}`);
            } else if (error.data.statusCode === 402) {
              this.$router.push(`/${this.$store.getters.getCreatedId()}`);
  
            } else {
              this.$router.push(`/${this.$store.getters.getCreatedId()}`);
            }
          })
      },

        
  
    },
  
    mounted() {
      this.generateOptions()
      // this.featchValidity();
    }
  
});
