
import { Vue } from "vue-property-decorator";

import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

/* tslint:disable:no-console */
declare let apigClientFactory: any;

export default Vue.extend({
  name: "login",

  data: () => {
    return {
      encode: "",
      date: "",
      submitted: false,
      isLoading: false,
      message: "",
      policyNumber: "",
      policyOwner: "",
      gender: ""
    };
  },

  validations: {
    date: {
      required
    }
  },

  methods: {
    showModal() {
      this.$root.$emit("bv::show::modal", "modal-1", "#btnShow");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-1", "#btnShow");
    },

    handleSubmit(e: any) {
      this.submitted = true;

      //stop here if from is invalid

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;
      // console.log('encode', this.$store.getters.getEncode());
      // console.log('date', this.date);
      let dateFormat = this.$moment(this.date).format("DDMMYY");
      // console.log('date format', dateFormat);
      const params = {
        // This is where any header, path, or querystring request params go.
      };
      const body = {
        // This is where you define the body of the request
        encode: this.$store.getters.getEncode(),
        dob: dateFormat
      };
      const additionalParams = {};
      const apigClient = apigClientFactory.newClient();
      apigClient
        .apiV1IdentityUserPost(params, body, additionalParams)
        .then((response: any) => {
          // console.log('response', response.data);
          this.$store.commit("SET_GENDER", response.data.gender);
          this.$store.commit("SET_POLICY_NUMBER", response.data.policyNumber);
          this.$store.commit("SET_POLICY_OWNER", response.data.policyOwner);
          this.$store.commit("SET_DOB", response.data.dob);
          this.$store.commit("SET_LOGGED_IN", true);
          // console.log('url', this.$store.getters.getOwnerHp);
          this.isLoading = false;
          this.$router.push("/");
        })
        .catch((error: any) => {
          if (error.data.statusCode === 402) {
            this.message = `Mohon maaf ${error.data.message} tercatat sudah memberikan respon atas konfirmasi ini. Terima Kasih atas partipasi Anda`;
            this.date = "";
            this.isLoading = false;
            this.showModal();
          } else {
            this.isLoading = false;
            this.date = "";
            this.message = `Tanggal Lahir ${this.gender} tidak sesuai `;
            this.showModal();
          }
        });
    },

    featchValidity() {
      const params = {
        // This is where any header, path, or querystring request params go.
      };
      const body = {
        // This is where you define the body of the request
        encode: this.$store.getters.getEncode()
      };
      const additionalParams = {};
      const apigClient = apigClientFactory.newClient();
      apigClient
        .apiV1IdentityValidatePost(params, body, additionalParams)
        .then((response: any) => {
          // console.log('response', response);
        })
        .catch((error: any) => {
          // console.log('error', error);
          if (error.data.statusCode === 400) {
            this.$router.push(`/${this.$store.getters.getEncode()}`);
          } else if (error.data.statusCode === 402) {
            this.$router.push(`/${this.$store.getters.getEncode()}`);
          } else {
            this.$router.push(`/${this.$store.getters.getEncode()}`);
          }
        });
    }
  },
  mounted() {
    this.gender = this.$store.getters.getGender()
    this.policyNumber = this.$store.getters.getPolicyNumber()
    this.policyOwner = this.$store.getters.getPolicyOwner()
    this.featchValidity();

    // this.date = "1990-01-01T00:00:00.000Z";
  }
});
