import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: 'sunlife-service-rating-app',
    storage: localStorage,
});

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],

  state: {
    roomId: '',
    createdId: '',
    customerPhoneNumber: '',
    customerName: '',
    loggedIn: false,
    isLogin: false,

  },
  mutations: {
    SET_ROOM_ID: (state: any, data) => {
      state.roomId = data;
    },
    SET_CREATED_ID: (state: any, data) => {
      state.createdId = data;
    },
    SET_CUSTOMER_PHONE_NUMBER: (state: any, data) => {
      state.customerPhoneNumber = data;
    },
    SET_CUSTOMER_NAME:  (state: any, data) => {
      state.customerName = data;
    },
    SET_LOGGED_IN: (state: any, data) => {
      state.loggedIn = data;
    },
    SET_IS_LOGIN: (state: any, data) => {
      state.isLogin = data;
    },

  },
  getters: {
    getRoomID: (state) => () => {
      return state.roomId;
    },
    getCreatedId: (state) => () => {
      return state.createdId;
    },
    getCustomerPhoneNumber: (state) => () => {
      return state.customerPhoneNumber;
    },
    getCustomerName: (state) => () => {
      return state.customerName
    },
    getLoggedIn: (state) => () => {
      return state.loggedIn;
    },
    getIsLogin: (state) => () => {
      return state.isLogin;
    },
  },
  actions: {

  },

});
