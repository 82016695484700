
import { Vue } from "vue-property-decorator";

import headerView from "./page/Header.vue";
import footerView from "./page/Footer.vue";

/* tslint:disable:no-console */
declare let apigClientFactory: any;

export default Vue.extend({
  name: "check",
  components: {
    headerView,
    footerView
  },

  data: () => {
    return {
      roomId: "",
      message: "",
      isWrongLink: false
    };
  },

  methods: {
    featchValidity() {
      const params = {
        // This is where any header, path, or querystring request params go.
      };
      const body = {
        // This is where you define the body of the request
        roomId:this.roomId
      };
      const additionalParams = {};
      const apigClient = apigClientFactory.newClient();
      apigClient
        .apiV1IdentityValidatePost(params, body, additionalParams)
        .then((response: any) => {
          // console.log("response", response)
          this.$store.commit("SET_ROOM_ID", response.data.roomId);
          this.$store.commit("SET_CUSTOMER_NAME", response.data.name);
          this.$store.commit("SET_CUSTOMER_PHONE_NUMBER", response.data.customerPhoneNumber);
          this.$router.push("/");
        })
        .catch((error: any) => {
          // console.log("error", error)
          if (error.data.message === "Expired Link") {
            this.isWrongLink = true;
            this.message = "Mohon Maaf Link Anda Sudah Kadaluwarsa";
          } else if (error.data.message === "otp not created") {
            this.isWrongLink = true;
            this.message =
              " Mohon maaf, kode OTP yang Anda masukan tidak valid. Untuk informasi lebih lanjut, silakan hubungi customer service kami di 1500786.  Terimakasih ";
          } else if (error.data.message === "otp max created") {
            this.isWrongLink = true;
            this.message =
              " Mohon maaf, kode OTP yang Anda masukan tidak valid. Untuk informasi lebih lanjut, silakan hubungi customer service kami di 1500786. Terimakasih ";
          } else if (error.data.statusCode === 401) {
            this.isWrongLink = true;
            let message = "";
            message = `Mohon maaf ${error.data.message}  tercatat telah mengisi rating. Mohon hubungi CS di nomor 1500786 atau email ke sli_care@sunlife.com. Terima kasih. `;
            this.message = message;
          } else {
            this.isWrongLink = true;
            this.message = "Mohon Maaf Anda sudah memberikan rating.  Untuk informasi lebih lanjut, silakan hubungi customer service kami di 1500786. Terimakasih   ";
          }
        });
    }
  },

  mounted() {
    const { params } = this.$route;
    console.log('params', params.pathMatch.replace("/", ""));
    if (params) {
      let paramsPatch = params.pathMatch.replace("/", "");
      const roomId = paramsPatch;
      if (roomId !== "*") {
        this.$store.commit("SET_CREATED_ID", roomId);
        this.$store.commit("SET_IS_LOGIN", true);
      }

      console.log("room id", roomId)

      // console.log("encode", this.$store.getters.getEncode())
      this.roomId = roomId;
      this.featchValidity();
    }
  }
});
